import React, { useContext, useEffect, useState } from 'react';
import Calendar from '../Calender/Calender';
import { AdminAuthoritiesContext } from '../../context/ContextForAdminPages';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { sendQuatation } from '../CommonCompo/PostQuatation';
import { changeStatusIfRequest } from '../CommonCompo/UpdateData';
import { logout } from '../CommonCompo/logOut';
import { useNavigate } from 'react-router-dom';

export default function DrDashBoard(props) {
    // ! error to show the form status
    const [error,setError] = useState('');
    const [errorShow,setErrorShow] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const [count,setCount] = useState(0);
    const navigate = useNavigate();
    const [selectedRequestId, setSelectedRequestId] = useState('');
    const {isAdmin} = useContext(AdminAuthoritiesContext);
    const [showCalender,setShowCalender] = useState(true);
    const [drRequests,setDrRequests] = useState(null);
    const [drProfile,setDrProfile] = useState(null);
    useEffect(()=>{
        // ? retriving the data from the server for the requests
        async function drAssignedRequest(){
            if(isAdmin?.user?.role !== undefined){
                await fetch('http://localhost:500/drProfile/getDrAssignedRequest',{
                headers: {'Content-Type': 'application/json'},
                method: 'POST',
                credentials:'include',
                body: JSON.stringify({
                    email:isAdmin?.user?.email
                })
                })
                .then(response => response.json())
                .then(data => {
                    if(data.status){
                        setDrRequests(data.requestData);
                        setDrProfile(data.dr);
                        console.log(data.dr);
                    } 
                })
                .catch(err => {
                    setError("Check Internet Connection");
                    setErrorShow(true);
                    console.log(err);
                });
            }
        }
        drAssignedRequest();
    },[count,isAdmin])


    const sendMail = (RequestId,currentUserMail,status) => {
        // console.log(currentUserMail,currentUserRequestId);
        sendQuatation(currentUserMail,"Dr On It's Way, We will come to you with in 1 Day")
        .then(data=>{
            setError('Request Updated successfully!');
            setFormSubmitted(true);
            changeStatusIfRequest(RequestId,status);
        })
        .catch(err=>{
            setError('Please try again!')
            setErrorShow(true);
        })
    }


    const sendRequest = async(currentUserRequestId,userName,email)=>{
        await fetch('http://localhost:500/ctopHub/requestFromDr',{
            method: 'PATCH',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                drAssigned: isAdmin.user.email,
                requestId: currentUserRequestId,
                name:userName,
                email:email
            })
        })
        .then(response => response.json())
        .then((data) => {
            setError("Request is registered successfully")
            setFormSubmitted(true);
            setCount(count + 1)
        })
        .catch(error => {
            setError("Something Went Wrong");
            setErrorShow(true);
        })
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    };

    const logOutFunction = async()=>{
        await logout()
        .then(data =>{
            if(data?.status){
                navigate('/login');
            } else {
                setError("Error During Logout");
                setErrorShow(true);
            }
        })
    }

    return(
        <div className=' h-full overflow-y-scroll bg-stone-50'>
            <nav className=' flex items-center bg-white justify-between p-2'>
                <div className='bg-white'>
                    <img src="/images/logo/CRT_Logo.png" alt="" className='rounded-md md:h-16 h-16'/>
                </div>
                <div className=''>
                    <h1 className='francois-one-regular text-xl font-15 text-blue-950'>CTOP INDIA MISSION</h1>
                </div>
                {/* <div>
                    <button onClick={()=> logOutFunction()} className='mx-5 md:block  text-white p-1 rounded-md bg-orange-600'>LogOut</button>
                </div> */}
            </nav>
            <div className=' p-5'>
                <nav className=' flex justify-between items-center p-5 bg-white shadow-md rounded-md'>
                        <div className=' py-5'>
                            <h1 className=' font-bold text-xl'>{isAdmin?.user?.name}</h1>
                            <p className=' text-xs font-bold text-slate-400'>{isAdmin?.user?.role === 'dr' && 'Doctor'}</p>
                        </div>
                        <div className="w-16 h-16 overflow-hidden rounded-full mr-4">
                            <img
                                src={drProfile?.imageString}
                                alt="Doctor"
                                className="w-full h-full object-cover rounded-full"
                            />
                        </div>
                    {/* <h1 className=' text-lg font-bold'>{isAdmin?.user?.name}</h1> */}
                    {/* <h1>{isAdmin?.user?.role}</h1> */}
                </nav>
                <div className='my-10'>
                    <button className='flex justify-center items-center p-2 bg-blue-950 rounded-md text-white' onClick={() => setShowCalender(!showCalender)}>
                        Manage Schedule.
                        <span class="material-symbols-outlined">
                            {showCalender ? "expand_less" : "expand_more"}
                        </span>
                    </button>
                    {showCalender && <Calendar/>}
                </div>
                <div className="flex justify-between mb-6">
    <button className="flex justify-center items-center p-2 bg-blue-950 rounded-md text-white">
        Assigned Patients
    </button>
    <button className="flex justify-center items-center p-2 text-black hover:shadow-lg rounded-full ">
        <span onClick={()=> setCount(count + 1)} className="material-symbols-outlined">refresh</span>
    </button>
</div>
<div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
    {drRequests?.map((value, index) => (
        <div className="bg-white shadow-md hover:scale-105 transition-transform duration-200 rounded-lg p-6" key={index}>
            <h2 className="text-xl font-bold mb-4">{value.request.careServiceRequest}</h2>
            <p className="text-gray-700 mb-2">
                <span className="font-bold">Name:</span> {value.request.firstName} {value.request.lastName}
            </p>
            <p className="text-gray-700 mb-2">
                <span className="font-bold">Phone Number:</span> {value.request.phoneNumber}
            </p>
            <p className="text-gray-700 mb-2">
                <span className="font-bold">Address:</span> {value.request.address}
            </p>
            <p className="text-gray-700 mb-2">
                <span className="font-bold">Concern:</span> {value.request.concern}
            </p>
            <p className="text-gray-700 mb-2">
                <span className="font-bold">Number of Patients:</span> {value.request.noPetient}
            </p>
            <p className="text-gray-700 mb-2">
                <span className="font-bold">Visit Date:</span> {value.approvedState === 'primary' ? formatDate(value.request.primaryDate) : formatDate(value.request.secodaryDate)}
            </p>
            <p className="text-gray-700 mb-2">
                <span className="font-bold">Arrival Time:</span> {value.request.arrivalTime}
            </p>
            <p className="text-gray-700 mb-2">
                <span className="font-bold">Status:</span> {value.request.status}
            </p>
            <p className="text-gray-700 mb-2">
                <span className="font-bold">Description:</span> {value.request.description}
            </p>
            {value?.request?.drAssigned === isAdmin?.user?.email ? (
                <button onClick={() => sendRequest(value.request._id,value.request.firstName+" " + value.request.lastName,value.request.email)} className="p-2 bg-blue-950 font-bold rounded-md text-white">Complete</button>
            ) : (
                <button onClick={() => sendRequest(value.request._id,value.request.firstName+" " + value.request.lastName,value.request.email)} className="p-2 bg-blue-950 font-bold rounded-md text-white">Confirm</button>
            )}
        </div>
    ))}
</div>

            </div>
            <Snackbar open={formSubmitted} autoHideDuration={2000} onClose={()=> setFormSubmitted(false)}>
                <MuiAlert
                elevation={6}
                variant="filled"
                onClose={()=>  setFormSubmitted(false)}
                severity="error"
                style={{backgroundColor:'green',color:'black'}}
                >
                    {error}
                </MuiAlert>
            </Snackbar>
            <Snackbar open={errorShow} autoHideDuration={2000} onClose={()=> setErrorShow(false)}>
                <MuiAlert
                elevation={6}
                variant="filled"
                onClose={()=> setErrorShow(false)}
                severity="error"
                style={{backgroundColor:'#ff9900',color:'black'}}
                >
                    {error}
                </MuiAlert>
        </Snackbar>
        </div>
    )
}