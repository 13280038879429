import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminAuthoritiesContext } from '../context/ContextForAdminPages';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DrDashBoard from './DrComponents/DrDashBoard';
import LogIn from '../Component/sidebarPages/LogIn/logIn';

export default function DashBoard({children}) {
    // ! error to show the form status
    const [error,setError] = useState('');
    const [errorShow,setErrorShow] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const [showData,setShowData] = useState(true);

    const [navbarController,setNavbarController] = useState(true);
    const toggleNavbarController = () => {
        setNavbarController(prevState => !prevState);
    };
    const navigate = useNavigate();
    const { isAdmin } = useContext(AdminAuthoritiesContext);

    const [notificationData,setNotificationData] = useState(null);
    const [notificationAnyalysis,setNotificationAnyalysis] = useState(null);
    const [drAnyalysis,setDrAnyalysis] = useState(null);
    useEffect(() => {
        async function fetchAnayslysis(){
            await fetch('http://localhost:500/ctophub/requestAnalysis',{
                headers: {'Content-Type': 'application/json'},
                method: 'GET',
                credentials:'include',
                })
                .then(response => response.json())
                .then(data => {
                    if(data.status){
                        setNotificationAnyalysis(data.data);
                        setDrAnyalysis(data.drRequests);
                        setNotificationData(data.notification);
                    } 
                })
                .catch(err => {
                    setError("Check Internet Connection");
                    setErrorShow(true);
                    console.log(err);
                });
        }
        fetchAnayslysis();
    }, [navbarController]);

    function slidebarFunction(){
    console.log("eee");
    setShowData(!showData);
    }
    
    return(
        <>
            <div className= {`h-screen w-screen ${isAdmin?.user?.role === 'dr' ? 'block' : 'hidden'} bg-slate-50`}>
                <DrDashBoard/>
            </div>
            <main className={` h-[100vh] w-[100vw] ${isAdmin?.user?.role !== 'dr' ? 'block' : 'hidden'}`} >
                <div onTransitionEnd={()=>slidebarFunction()} className={`fixed left-0 top-0 h-full bg-white z-50 ${isAdmin?.user?.role === 'dr' ? 'hidden' : 'block'} transition-all bg-indigo-50 duration-700 ${navbarController ? 'w-x-64' : 'hidden'}`}>
                    <div className={` flex justify-between bg-gradient-to-tr from-indigo-950 to-indigo-800 rounded-xl mt-2 items-center px-2 ${showData ? 'block':'hidden'}`}>
                        <a href="#" className={`flex items-center py-4`}>
                            <h2 className="font-bold md:text-md text-slate-50">CTOP INDIA MISSION</h2>
                        </a>
                        <button  onClick={toggleNavbarController} className={`${navbarController ? 'block':'hidden'} hover:bg-indigo-500 rounded-2xl`}>
                            <span className="material-symbols-outlined p-4 text-slate-100" >
                                menu_open
                            </span>
                        </button>
                    </div>
                    {/* 
                    // ?--------------------------------------------------------------------
                    //?------------------------------ side bar------------------------------
                    //?---------------------------------------------------------------------
                    */}
                    <ul className={` mt-4 overflow-y-scroll hiddenScroll h-full pb-20  ${showData ? 'block':'hidden'}`}>
                        {/* <span className="text-gray-400 font-bold pl-5">ADMIN</span> */}
                        {
                            isAdmin?.user?.role === "masterAdmin"  &&
                            <li onClick={()=> navigate('/analysis')} className="mb-1 group">
                                <a href="" className="flex font-semibold items-center py-2 px-4 text-gray-900 hover:bg-indigo-950 hover:text-gray-100 rounded-md group-[.active]:bg-gray-800 group-[.active]:text-white group-[.selected]:bg-gray-950 group-[.selected]:text-gray-100">
                                    <span className="text-sm">Home</span>
                                </a>
                            </li>
                        }
                        {
                            isAdmin?.user?.role === 'dr'  &&
                            <li onClick={()=> navigate('/DrDashBoard')} className="mb-1 group">
                            <a href="" className="flex font-semibold items-center py-2 px-4 text-gray-900 hover:bg-indigo-950 hover:text-gray-100 rounded-md group-[.active]:bg-gray-800 group-[.active]:text-white group-[.selected]:bg-gray-950 group-[.selected]:text-gray-100">
                                <span className="text-sm">Requested Petients</span>
                            </a>
                        </li>
                        }
                        
                        <li onClick={()=> navigate('/')} className="mb-1 group">
                            <a href="" className="grid grid-cols-3 font-semibold items-center py-2 px-4 text-gray-900 hover:bg-indigo-950 hover:text-gray-100 rounded-md group-[.active]:bg-gray-800 group-[.active]:text-white group-[.selected]:bg-gray-950 group-[.selected]:text-gray-100">
                                <span className="text-sm col-span-2">Dr Home Care</span>
                                <div>
                                    {notificationAnyalysis?.DrhomeServiceRequests?.requested > 0 && <i className='mx-1 px-2 bg-red-50 border-2 rounded-full border-red-500 text-red-500 animate-pulse'>{notificationAnyalysis?.DrhomeServiceRequests?.requested}</i>}
                                    {notificationAnyalysis?.DrhomeServiceRequests?.confirmed > 0 && <i className='mx-1 px-2 bg-green-50 border-2 rounded-full border-green-500 text-green-700 animate-pulse'>{notificationAnyalysis?.DrhomeServiceRequests?.confirmed}</i>}
                                </div>
                            </a>
                        </li>
                        <li onClick={()=>{navigate('/nursingDash')}} className="mb-1 group">
                            <a href="" className="grid grid-cols-3 font-semibold items-center py-2 px-4 text-gray-900 hover:bg-indigo-950 hover:text-gray-100 rounded-md group-[.active]:bg-gray-800 group-[.active]:text-white group-[.selected]:bg-gray-950 group-[.selected]:text-gray-100 sidebar-dropdown-toggle">
                                <span className="text-sm col-span-2">Nursing Care Service</span>
                                <div>
                                    {notificationAnyalysis?.NursingRequests?.requested > 0 && <i className='mx-1 px-2 bg-red-50 border-2 rounded-full border-red-500 text-red-500 animate-pulse'>{notificationAnyalysis?.NursingRequests?.requested}</i>}
                                    {notificationAnyalysis?.NursingRequests?.confirmed > 0 && <i className='mx-1 px-2 bg-green-50 border-2 rounded-full border-green-500 text-green-700 animate-pulse'>{notificationAnyalysis?.NursingRequests?.confirmed}</i>}
                                </div>
                            </a>
                        </li>
                        <li onClick={()=>{navigate('/telehealthDash')}} className="mb-1 group">
                            <a href="" className="grid grid-cols-3 font-semibold items-center py-2 px-4 text-gray-900 hover:bg-indigo-950 hover:text-gray-100 rounded-md group-[.active]:bg-gray-800 group-[.active]:text-white group-[.selected]:bg-gray-950 group-[.selected]:text-gray-100 sidebar-dropdown-toggle">
                                <span className="text-sm col-span-2">Telehealth Service</span>
                                <div>
                                    {notificationAnyalysis?.telehealthCareRequests?.requested > 0 &&  <i className='mx-1 px-2 bg-red-50 border-2 rounded-full border-red-500 text-red-500 animate-pulse'>{notificationAnyalysis?.telehealthCareRequests?.requested}</i>}
                                    {notificationAnyalysis?.telehealthCareRequests?.confirmed > 0 && <i className='mx-1 px-2 bg-green-50 border-2 rounded-full border-green-500 text-green-700 animate-pulse'>{notificationAnyalysis?.telehealthCareRequests?.confirmed}</i>}
                                </div>
                            </a>
                        </li>
                        
                        <li onClick={()=>{navigate('/petientTransportDash')}} className="mb-1 group">
                            <a href="" className="grid grid-cols-3 font-semibold items-center py-2 px-4 text-gray-900 hover:bg-indigo-950 hover:text-gray-100 rounded-md group-[.active]:bg-gray-800 group-[.active]:text-white group-[.selected]:bg-gray-950 group-[.selected]:text-gray-100">            
                                <span className="text-sm col-span-2">Patient Transport</span>
                                <div>
                                    {notificationAnyalysis?.petientTransportrRequest?.requested > 0 && <i className='mx-1 px-2 bg-red-50 border-2 rounded-full border-red-500 text-red-500 animate-ping'>{notificationAnyalysis?.petientTransportrRequest?.requested}</i>}
                                    {notificationAnyalysis?.petientTransportrRequest?.confirmed > 0 && <i className='mx-1 px-2 bg-green-50 border-2 rounded-full border-green-500 text-green-700 animate-pulse'>{notificationAnyalysis?.petientTransportrRequest?.confirmed}</i>}
                                </div>
                            </a>
                            </li>
                        <li onClick={()=>{navigate('/SchoolDash')}} className="mb-1 group">
                            <a href="" className="grid grid-cols-3 font-semibold items-center py-2 px-4 text-gray-900 hover:bg-indigo-950 hover:text-gray-100 rounded-md group-[.active]:bg-gray-800 group-[.active]:text-white group-[.selected]:bg-gray-950 group-[.selected]:text-gray-100 sidebar-dropdown-toggle">
                                <span className="text-sm col-span-2">School Care Service</span>
                                <div>
                                    {notificationAnyalysis?.schoolName?.requested > 0 && <i className='mx-1 px-2 bg-red-50 border-2 rounded-full border-red-500 text-red-500 animate-pulse'>{notificationAnyalysis?.schoolName?.requested}</i>}
                                    {notificationAnyalysis?.schoolName?.confirmed > 0 && <i className='mx-1 px-2 bg-green-50 border-2 rounded-full border-green-500 text-green-700 animate-pulse'>{notificationAnyalysis?.schoolName?.confirmed}</i>}
                                </div>
                            </a>
                        </li>
                        <li onClick={()=>{navigate('/workcare')}} className="mb-1 group">
                            <a href="" className="grid grid-cols-3 font-semibold items-center py-2 px-4 text-gray-900 hover:bg-indigo-950 hover:text-gray-100 rounded-md group-[.active]:bg-gray-800 group-[.active]:text-white group-[.selected]:bg-gray-950 group-[.selected]:text-gray-100">                
                                <span className="text-sm col-span-2">Work Care Service</span>
                                <div>
                                {notificationAnyalysis?.WorkCareRequest?.requested > 0 && <i className='mx-1 px-2 bg-red-50 border-2 rounded-full border-red-500 text-red-500 animate-pulse'>{notificationAnyalysis?.WorkCareRequest?.requested}</i>}
                                {notificationAnyalysis?.WorkCareRequest?.confirmed > 0 && <i className='mx-1 px-2 bg-green-50 border-2 rounded-full border-green-500 text-green-700 animate-pulse'>{notificationAnyalysis?.WorkCareRequest?.confirmed}</i>}
                                </div>
                            </a>
                        </li>
                        <li onClick={()=>{navigate('/ctopHubDash')}} className="mb-1 group">
                            <a href="" className="grid grid-cols-3 font-semibold items-center py-2 px-4 text-gray-900 hover:bg-indigo-950 hover:text-gray-100 rounded-md group-[.active]:bg-gray-800 group-[.active]:text-white group-[.selected]:bg-gray-950 group-[.selected]:text-gray-100">                
                                <span className="text-sm col-span-2">CTOP HUB Service</span>
                                <div>
                                {notificationAnyalysis?.CTOPHubRequest?.confirmed > 0 && <i className='mx-1 px-2 bg-green-50 border-2 rounded-full border-green-500 text-green-700 animate-pulse'>{notificationAnyalysis?.CTOPHubRequest?.confirmed}</i>}                              
                                {notificationAnyalysis?.CTOPHubRequest?.requested > 0 && <i className='mx-1 px-2 bg-red-50 border-2 rounded-full border-red-500 text-red-500 animate-pulse'>{notificationAnyalysis?.CTOPHubRequest?.requested}</i>}
                                </div>
                            </a>
                        </li>
                        <li onClick={()=>{navigate('/diagnosticDash')}} className="mb-1 group">
                            <a href="" className="grid grid-cols-3 font-semibold items-center py-2 px-4 text-gray-900 hover:bg-indigo-950 hover:text-gray-100 rounded-md group-[.active]:bg-gray-800 group-[.active]:text-white group-[.selected]:bg-gray-950 group-[.selected]:text-gray-100">                
                                <span className="text-sm col-span-2">Diagnostic And Imaging</span>
                                <div>
                                                                    
                                {notificationAnyalysis?.DiagnosisRequest?.requested > 0 && <i className='mx-1 px-2 bg-red-50 border-2 rounded-full border-red-500 text-red-500 animate-pulse'>{notificationAnyalysis?.DiagnosisRequest?.requested}</i>}
                                {notificationAnyalysis?.DiagnosisRequest?.confirmed > 0 && <i className='mx-1 px-2 bg-green-50 border-2 rounded-full border-green-500 text-green-700 animate-pulse'>{notificationAnyalysis?.DiagnosisRequest?.confirmed}</i>}
                                </div>
                                
                            </a>
                        </li>
                        <li onClick={()=>{navigate('/pathalogyDash')}} className="mb-1 group">
                            <a href="" className="grid grid-cols-3 font-semibold items-center py-2 px-4 text-gray-900 hover:bg-indigo-950 hover:text-gray-100 rounded-md group-[.active]:bg-gray-800 group-[.active]:text-white group-[.selected]:bg-gray-950 group-[.selected]:text-gray-100">                
                                <span className="text-sm col-span-2">CTOP Pathalogy Service</span>
                                <div>
                                                                    
                                {notificationAnyalysis?.PathalogyRequests?.requested > 0 && <i className='mx-1 px-2 bg-red-50 border-2 rounded-full border-red-500 text-red-500 animate-pulse'>{notificationAnyalysis?.PathalogyRequests?.requested}</i>}
                                {notificationAnyalysis?.PathalogyRequests?.confirmed > 0 && <i className='mx-1 px-2 bg-green-50 border-2 rounded-full border-green-500 text-green-700 animate-pulse'>{notificationAnyalysis?.PathalogyRequests?.confirmed}</i>}
                                </div>
                                
                            </a>
                        </li>
                        <li onClick={()=>{navigate('/pharmacyDash')}} className="mb-1 group">
                            <a href="" className="grid grid-cols-3 font-semibold items-center py-2 px-4 text-gray-900 hover:bg-indigo-950 hover:text-gray-100 rounded-md group-[.active]:bg-gray-800 group-[.active]:text-white group-[.selected]:bg-gray-950 group-[.selected]:text-gray-100">                
                                <span className="text-sm col-span-2">Pharmacy Service</span>
                                <div>                                   
                                {notificationAnyalysis?.PharmacyRequest?.requested > 0 && <i className='mx-1 px-2 bg-red-50 border-2 rounded-full border-red-500 text-red-500 animate-pulse'>{notificationAnyalysis?.PharmacyRequest?.requested}</i>}
                                {notificationAnyalysis?.PharmacyRequest?.confirmed > 0 && <i className='mx-1 px-2 bg-green-50 border-2 rounded-full border-green-500 text-green-700 animate-pulse'>{notificationAnyalysis?.PharmacyRequest?.confirmed}</i>}
                                </div>
                                
                            </a>
                        </li>
                        <li onClick={()=>{navigate('/drRequests')}} className="mb-1 group">
                            <a href="" className="grid grid-cols-3 font-semibold items-center py-2 px-4 text-gray-900 hover:bg-indigo-950 hover:text-gray-100 rounded-md group-[.active]:bg-gray-800 group-[.active]:text-white group-[.selected]:bg-gray-950 group-[.selected]:text-gray-100">                
                                <span className="text-sm col-span-2">Practitionar Requests</span>
                                <div>                                   
                                {drAnyalysis?.requested > 0 && <i className='mx-1 px-2 bg-red-50 border-2 rounded-full border-red-500 text-red-500 animate-pulse'>{drAnyalysis?.requested}</i>}
                                {drAnyalysis?.approved > 0 && <i className='mx-1 px-2 bg-green-50 border-2 rounded-full border-green-500 text-green-700 animate-pulse'>{drAnyalysis?.approved}</i>}
                                </div>
                            </a>
                        </li>
                        <li onClick={()=>{navigate('/otherRequestDash')}} className="mb-1 group">
                            <a href="" className="grid grid-cols-3 font-semibold items-center py-2 px-4 text-gray-900 hover:bg-indigo-950 hover:text-gray-100 rounded-md group-[.active]:bg-gray-800 group-[.active]:text-white group-[.selected]:bg-gray-950 group-[.selected]:text-gray-100">                
                                <span className="text-sm col-span-2">Other Requests</span>
                                <div>
                                {notificationAnyalysis?.otherRequests?.confirmed > 0 && <i className='mx-1 px-2 bg-green-50 border-2 rounded-full border-green-500 text-green-700 animate-pulse'>{notificationAnyalysis?.otherRequests?.confirmed}</i>}                              
                                {notificationAnyalysis?.otherRequests?.requested > 0 && <i className='mx-1 px-2 bg-red-50 border-2 rounded-full border-red-500 text-red-500 animate-pulse'>{notificationAnyalysis?.otherRequests?.requested}</i>}
                                </div>
                            </a>
                        </li>
                        {
                            
                        (isAdmin?.user?.role === 'admin' || isAdmin?.user?.role === 'masterAdmin')  && 
                        <>
                            <li onClick={()=>{navigate('/drRequestHandling')}} className="mb-1 group">
                                <a href="" className="flex font-semibold items-center py-2 px-4 text-gray-900 hover:bg-indigo-950 hover:text-gray-100 rounded-md group-[.active]:bg-gray-800 group-[.active]:text-white group-[.selected]:bg-gray-950 group-[.selected]:text-gray-100">                
                                    <span className="text-sm">Dr Addition</span>
                                </a>
                            </li>
                            {isAdmin?.user?.role !== 'admin' &&
                                <li onClick={()=>{navigate('/minorAdminAdding')}} className="mb-1 group">
                                    <a href="" className="flex font-semibold items-center py-2 px-4 text-gray-900 hover:bg-indigo-950 hover:text-gray-100 rounded-md group-[.active]:bg-gray-800 group-[.active]:text-white group-[.selected]:bg-gray-950 group-[.selected]:text-gray-100">                
                                        <span className="text-sm">Admin Addition</span>
                                    </a>
                                </li>
                            }
                        </>
                        }
                    </ul>
                </div>
        
                <main className={`h-full overflow-y-scroll overflow-x-hidden ${navbarController ? 'md:ml-64 ' : ''} transition-all duration-700 bg-gray-200 z-30 min-h-screen main`}>
                    <div className="py-2 px-2 bg-indigo-800 flex justify-between items-center shadow-md shadow-black/5 sticky top-0 left-0 z-40">
                        {/* <button type="button" className="text-lg text-gray-900 font-semibold sidebar-toggle">
                            <i className="ri-menu-line"></i>    
                        </button> */}
                        <div className='text-white flex'>
                            <button  className={`${navbarController ? 'hidden':'block'}`}>
                                <span className="material-symbols-outlined py-1 md:p-4 hover:bg-indigo-700 transition-colors duration-300 rounded-2xl" onClick={toggleNavbarController}>
                                    menu
                                </span>
                            </button>
                            <a href="#" className={`flex items-center pb-4 p-4 `}>
                                <h2 className={`font-bold text-base md:text-2xl duration-500 transition-opacity opacity-100 ${navbarController ? 'hidden':'block'}`}>CTOP INDIA MISSION</h2>
                            </a>
                        </div>
                        <ul className="ml-auto flex items-center">
                            <li className="relative group ">
                                    <button  className=' relative'>
                                        <span className="relative flex h-3 w-3">
                                            <span className={`animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 ${notificationAnyalysis?.petientTransportrRequest?.requested > 0 && 'bg-red-400'}  opacity-75`}></span>
                                            <span className={`relative inline-flex rounded-full h-3 w-3 bg-blue-500  ${notificationAnyalysis?.petientTransportrRequest?.requested > 0 && 'bg-red-400'}`}></span>
                                        </span>
                                        <span className='material-symbols-outlined text-slate-50'>
                                            notifications_active
                                        </span>
                                    </button>
                                    <ul className=' bg-slate-100 fixed w-fit hidden group-hover:flex flex-col rounded-md'>
                                        <li className='p-1 hover:bg-slate-300 rounded-md grid grid-cols-4'>
                                            <h6 className=' col-span-3'>Requests</h6>
                                            <p className=' text-center'>{notificationData?.requests}</p>
                                        </li>
                                        <li className='p-1 hover:bg-slate-300 rounded-md grid grid-cols-4'>
                                            <h6 className=' col-span-3'>Confirmation</h6>
                                            <p className=' text-center'>{notificationData?.confirmed}</p>
                                        </li>
                                        {
                                            notificationAnyalysis?.petientTransportrRequest?.requested > 0 && 
                                            <li onClick={()=>{navigate('/petientTransportDash')}} className={`p-1 text-red-800 hover:bg-slate-300 rounded-md grid grid-cols-4 items-center animate-ping`}>
                                                <h6 className=' col-span-3'>Transportation</h6>
                                                <p className=' text-center'>{notificationAnyalysis?.petientTransportrRequest?.requested}</p>
                                            </li>
                                        }
                                    </ul>
                            </li>
                            <li className="dropdown ml-3">
                                <button type="button" className="dropdown-toggle flex items-center">
                                    <div className="p-2 md:block text-left">
                                        <h2 className="text-sm font-semibold text-gray-300">{isAdmin ? isAdmin.user.name : 'John Doe'}</h2>
                                        <p className="text-xs text-gray-400">{isAdmin?.user.role === 'dr' ? "CTOP Doctor" : 'Administrator'}</p>
                                    </div>                
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className='h-full w-full overflow-x-hidden'>
                        {children}
                    </div>
                </main>
                <Snackbar open={formSubmitted} autoHideDuration={2000} onClose={()=> setFormSubmitted(false)}>
                    <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={()=>  setFormSubmitted(false)}
                    severity="success"
                    style={{backgroundColor:'green',color:'black'}}
                    >
                        {error}
                    </MuiAlert>
                </Snackbar>
                <Snackbar open={errorShow} autoHideDuration={2000} onClose={()=> setErrorShow(false)}>
                    <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={()=> setErrorShow(false)}
                    severity="error"
                    style={{backgroundColor:'#ff9900',color:'black'}}
                    >
                        {error}
                    </MuiAlert>
                </Snackbar>
            </main>
        </>
        )        
    }