import React, { Children, useContext, useEffect } from 'react';
import { AdminAuthoritiesContext } from '../context/ContextForAdminPages';
import { checkAuth } from '../Component/Authenticate/CheckAuth';


export default function SetUserContext({children}) {
    const {isAdmin,setIsAdmin} = useContext(AdminAuthoritiesContext);

    const autherize = async () => {
        try {
            const data = await checkAuth(); // Assuming checkAuth returns a promise
            setIsAdmin(data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(()=>{
        fetch('http://localhost:500/activity');
        autherize();
    },[])
    return(
        <>{children}</>
    )
}