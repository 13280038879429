import React, { useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import { useSidebar } from './sidebarProviderContext';

export default function Navigation(props) {
    const [buttonState,setButtonState] = useState(false);
    const [ctopProgramButton,setCtopProgramButton] = useState(false);
    const [workCare,setWorkCare] = useState(false);
    const [healthCare,setHealthCare] = useState(false);
    const navigate = useNavigate();
    const {isMenuBar,setIsMenuBar} = useSidebar();

    function pathchoose(route){
        // closeSidebar();
        // props.handleSidebar(false);
        navigate(route);
        setIsMenuBar(!isMenuBar);
        // console.log("clicked");
    }
    function closeSidebar(){
        props.setIsSidebar(!props.isSidebar);
    }
    return(
        <div className={`sticky top-0 w-full h-12 z-30`}>
            <div className='hidden h-12 md:flex bg-violet-200 justify-between items-center text-black shadow-md overflow-y-scroll'>
                <button className='text-violet-700' onClick={()=> closeSidebar()}>  
                <span className="material-symbols-outlined p-3 hover:bg-violet-500 hover:text-white transition-colors duration-300">
                    {
                    isMenuBar || props.isSidebar
                    ?
                    'close'
                    :
                    'menu'
                    }
                    
                </span>
                </button>
                <div className='hidden sm:flex justify-around items-center '>
                    <ul className='flex'>
                        <li onClick={()=> navigate('/program/home')} className='p-3 mx-2 hover:bg-violet-300 cursor-pointer'>
                            Home
                        </li>
                        <li onClick={()=> {navigate('/aboutus');}}  className='p-3 px-1 mx-1 hover:bg-violet-300 cursor-pointer transition-colors duration-300'>
                        About Us
                        </li>
                        <li onClick={()=> navigate('/program/team')} className='p-3 px-1 mx-1 hover:bg-violet-300 cursor-pointer transition-colors duration-300'>
                            Team
                        </li>
                        <li onClick={()=>{navigate('/program/care-services');}} className='p-3 px-1 mx-1 hover:bg-violet-300 cursor-pointer transition-colors duration-300'>
                        Services
                        </li>
                        <li onClick={()=>navigate('/appointment')} className='p-3 px-1 mx-1 hover:bg-violet-300 cursor-pointer transition-colors duration-300'>
                        Appointment
                        </li>
                        
                        <li  onClick={()=> navigate('/appointment')}  className='p-3 px-1 mx-1 hover:bg-violet-300 cursor-pointer transition-colors duration-300'>
                            Professionals
                        </li>
                        {/* <li  onClick={()=> navigate('/appointment')}  className='p-3 px-1 mx-1 hover:bg-violet-300 cursor-pointer transition-colors duration-300'>
                            Daignostic & Imaging
                        </li> */}
                        <li  onClick={()=> navigate('/prescription')}  className='p-3 px-1 mx-1 hover:bg-violet-300 cursor-pointer transition-colors duration-300'>
                            Pharmacy
                        </li>
                        <li onClick={()=> navigate('/screening')}  className='p-3 px-1 mx-1 hover:bg-violet-300 cursor-pointer transition-colors duration-300'>
                            Health Screeening
                        </li>
                        <li onClick={()=> window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSeApe1LOulwTHFuedmTIXNnEDoeirlySEnd1rH84naMr1XJYQ/viewform?pli=1'}  className='p-3 px-1 mx-1 hover:bg-violet-300 cursor-pointer transition-colors duration-300'>
                        Assesment
                        </li>
                        
                        <li onClick={()=> navigate('/gallery')}  className='p-3 px-1 mx-1 hover:bg-violet-300 cursor-pointer transition-colors duration-300'>
                        Gallery
                        </li>
                        
                        
                        
                        <li onClick={()=> navigate('/faqs')}  className='p-3 px-1 mx-1 hover:bg-violet-300 cursor-pointer transition-colors duration-300'>
                        FAQ
                        </li>
                        
                        {/* <li onClick={()=>navigate('/prescription')}   className='p-1 mx-2 hover:bg-violet-300'>
                        Prescriptions
                        </li> */}
                        {/* <li onClick={()=>navigate('/certification')}  className='p-1 mx-2 hover:bg-violet-300'>
                        Medical Certificates
                        </li> */}
                    </ul>
                    <div>
                        <button onClick={()=> navigate('/login')} className='mx-5 border p-2 rounded-md bg-orange-500 text-white shadow-xl shadow-slate-500' >Log in</button>
                        {/* <button className=' p-1 font-bold rounded-md bg-green-600'>Get Started</button> */}
                    </div>
                </div>
            </div>
        

        <div className={`${isMenuBar ? "justify-between flex-col " : "justify-end"}h-[100vh] flex overflow-y-scroll hiddenScroll z-30  justify-between items-center w-full md:hidden bg-violet-200 text-black overflow-x-hidden`}>
            <button className='m-1 self-start p-1' onClick={()=> setIsMenuBar(!isMenuBar)}>
                <span className="material-symbols-outlined">
                {isMenuBar ? "close" : "menu"}
                </span>
            </button>
            <div className= 'block md:hidden'>
                    <div className={`flex flex-wrap justify-center items-center p-5 mx-5 ${!isMenuBar ? "hidden" : "block"}`}>
                    <button  onClick={()=> pathchoose('/csr')}  className='flex min-w-24 text-right justify-center bg-orange-300 shadow-lg shadow-slate-400 text-blue-950 font-extrabold p-2 rounded-md ml-1 text-xs m-1'>CSR</button>
                        <button  onClick={()=> pathchoose('/appointment')}  className='flex min-w-24 text-right justify-center bg-orange-300 shadow-lg shadow-slate-400 text-blue-950 font-extrabold p-2 rounded-md ml-1 text-xs m-1'>Professionals</button>
                        <button   onClick={()=> pathchoose('/prescription')}   className='flex min-w-24 text-right justify-center bg-orange-300 shadow-lg shadow-slate-400 text-blue-950 font-extrabold p-2 rounded-md ml-1 text-xs m-1'>Pharmacy</button>
                        <button  onClick={()=> window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLScj8thMXMRcgKJFEIT-nKkBUKKXH4lQkJckjrp_6UZ3GKkzmA/viewform?usp=sf_link'}   className='flex min-w-24 text-right justify-center bg-orange-300 shadow-lg shadow-slate-400 text-blue-950 font-extrabold p-2 rounded-md m-1 text-xs'>Contact Us</button>
                        <button  onClick={()=> pathchoose('/CurrentLocations')}  className='flex min-w-24 text-right justify-center bg-orange-300 shadow-lg shadow-slate-400 text-blue-950 font-extrabold p-2 rounded-md ml-1 text-xs'>Project Loaction</button>
                        <div className='relative group z-50'>
                            <button onClick={()=> window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLScj8thMXMRcgKJFEIT-nKkBUKKXH4lQkJckjrp_6UZ3GKkzmA/viewform?usp=sf_link'} className='flex min-w-24 text-right justify-center bg-orange-300 cursor-pointer shadow-lg shadow-slate-400 text-blue-950 font-extrabold p-2 rounded-md m-1 text-xs'>Career</button>
                            <ul className="absolute z-30 hidden group-hover:block group right-0 text-xs font-bold bg-orange-50 text-gray-800 shadow-lg rounded-md">
                                <li className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Internship</li>
                                <li className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Placement</li>
                                <li className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Training</li> 
                                <li className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Job </li>
                                <li className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Contract</li>
                                <li className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Providers</li>
                            </ul>
                        </div>

                    
                        <div className=' relative group z-50'>
                            <button  onClick={()=> pathchoose('/buisness')}   className='flex min-w-24 text-right justify-center bg-orange-300 shadow-lg shadow-slate-400 text-blue-950 font-extrabold cursor-pointer p-2 rounded-md ml-1 text-xs'>Business</button>
                            <ul className="absolute z-30 hidden group-hover:block right-0 text-xs font-bold bg-orange-50 text-gray-800 shadow-lg rounded-md">
                                <li className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Investment</li>
                                <li className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Franchise</li>
                                <li className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Distributer</li>
                                <li className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Contract</li>
                                <li className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Vendor Supply</li>
                                <li className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Providers</li>
                            </ul>
                        </div>
                        <button  onClick={()=> window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLScj8thMXMRcgKJFEIT-nKkBUKKXH4lQkJckjrp_6UZ3GKkzmA/viewform?usp=sf_link'}  className='hidden md:flex min-w-24 text-right justify-center bg-orange-400 shadow-lg shadow-slate-400 text-blue-950 font-extrabold p-2 rounded-md ml-1 cursor-pointer text-xs'>Contact Us</button>
                        {/* 
                        //todo : we can convert this button name to Member Dashboard
                        */}
                        <button  onClick={()=> {
                        pathchoose('/user_requests/service_request')
                        // window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSdx57l0ZUZNctasszCmroAJAka8SsRGJCdik-LYKUpJsxaNVg/viewform?usp=sf_link'
                        }}    className='flex min-w-24 text-right justify-center bg-orange-300 shadow-lg shadow-slate-400 text-blue-950 font-extrabold p-2 cursor-pointer rounded-md m-1 text-xs'>Service Request </button>
                    </div>
                    
                </div>
            <div className={`${isMenuBar ? 'h-[90vh]' : 'h-fit'}`}>
                <ul className={ `${isMenuBar ? "block":"hidden"}  w-full transition-all overflow-y-scroll duration-500 ease-out`}>
                    <li onClick={()=> pathchoose('/program/home')}  className='px-5 py-3 w-screen hover:bg-orange-400 bg-orange-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 text-sm'>
                    Log in
                    </li>
                    
                    <li onClick={()=> setCtopProgramButton(!ctopProgramButton)} className={`px-5 py-3 cursor-pointer
                    ${ctopProgramButton ? 'bg-violet-400':'bg-white'}
                    hover:bg-violet-400  transition-all duration-500 ease-out  flex justify-between items-center border-violet-500 text-sm`}>
                        CTOP Program
                        <span className="material-symbols-outlined">
                            {
                                ctopProgramButton ?
                                'expand_less'
                                :
                                'expand_more'   
                            }
                        </span>
                    </li>
                    {
                        ctopProgramButton?
                        <ul className='bg-violet-200  rounded-sm p-5'>
                                <li  onClick={()=> pathchoose('/program/home')}  className=' my-2  text-sm hover:bg-violet-300 rounded-sm p-4 cursor-pointer transition-all duration-500' >
                                Home
                            </li>
                            <li onClick={()=> {pathchoose('/aboutus');}} className=' my-2  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-4 cursor-pointer' >
                                About Us
                            </li>
                            <li onClick={()=> pathchoose('/program/team')} className=' my-2  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-4 cursor-pointer' >
                                Team
                            </li>
                            {/* <li className=' my-2  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-4 cursor-pointer' >
                                Clinician
                            </li> */}
                            <li onClick={()=> pathchoose('/program/care-services')} className=' my-2  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-4 cursor-pointer' >
                                Care Services
                            </li>
                        </ul>
                        
                        :
                        ''
                    }
                    <li onClick={()=> setButtonState(!buttonState)} className={`px-5 py-3 cursor-pointer
                    ${buttonState ? 'bg-violet-400':'bg-white'}
                    hover:bg-violet-400  transition-all duration-500 ease-out  flex justify-between items-center border-t-2 border-violet-300 text-sm`}>
                        CTOP Panchayat Hub
                        <span className="material-symbols-outlined">
                            {
                                buttonState ?
                                'expand_less'
                                :
                                'expand_more'
                            }
                        </span>
                    </li>
                    {
                        buttonState?
                        <ul className='bg-violet-200  rounded-sm p-5'>
                            <li onClick={()=> pathchoose('/panchayat/healthBeing')} className=' my-2  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-4 cursor-pointer' >
                                HealthCare & welbeing
                            </li>
                            <li onClick={()=> pathchoose('/narsing')} className=' my-2  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-4 cursor-pointer' >
                                Nursing
                            </li>
                            <li onClick={()=> pathchoose('/mainForm')}  className=' my-2  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-4 cursor-pointer' >
                                Service Booking
                            </li>
                            <li onClick={()=> pathchoose('/panchayat/memberInsurance')} className=' my-2  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-4 cursor-pointer' >
                                CTOP Hub Membership
                            </li>
                            <li onClick={()=> pathchoose("/panchayat/groupInsurance")} className=' my-2 hover:bg-violet-300 transition-all duration-400 rounded-sm text-sm p-4 cursor-pointer' >
                                CTOP Panchayat Group Insurance
                            </li>
                            <li  onClick={()=> pathchoose("/panchayat/Subscription")}  className=' my-2  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-4 cursor-pointer' >
                                Subscription
                            </li>
                        </ul>
                        
                        :
                        ''
                    }
                    <li onClick={()=> setHealthCare(!healthCare)}   className={`px-5 py-3 cursor-pointer
                    ${healthCare ? 'bg-violet-400':'bg-white'}
                    hover:bg-violet-400  transition-all duration-500 ease-out  flex justify-between items-center border-t-2 border-violet-300 text-sm`}>
                        CTOP Home Health Care
                        <span className="material-symbols-outlined">
                            {
                                healthCare ?
                                'expand_less'
                                :
                                'expand_more'
                            }
                        </span>
                    </li>
                    {
                        healthCare?
                        <ul className='bg-violet-200  rounded-sm p-5'>
                            <li onClick={()=> pathchoose('/HomeHealthCare')} className=' my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-4' >
                                Home Health  Care
                            </li>
                            <li onClick={()=> pathchoose('/narsing')} className=' my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-4' >
                                Home Care Nursing
                            </li>
                            <li onClick={()=> window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLScj8thMXMRcgKJFEIT-nKkBUKKXH4lQkJckjrp_6UZ3GKkzmA/viewform?usp=sf_link'} className=' my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-4' >
                                Home Care Booking
                            </li>
                            <li className=' my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-4' >
                                Home Care Membership
                            </li>
                            <li className=' my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-4' >
                                Home Care Consumable
                            </li>
                            <li onClick={()=> window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLScj8thMXMRcgKJFEIT-nKkBUKKXH4lQkJckjrp_6UZ3GKkzmA/viewform?usp=sf_link'} className=' my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-4' >
                                Home Care Transport
                            </li>
                        </ul>
                        
                        :
                        ''
                    }
                    <li onClick={()=> setWorkCare(!workCare)}   className={`px-5 py-3 cursor-pointer
                    ${workCare ? 'bg-violet-400':'bg-white'}
                    hover:bg-violet-400  transition-all duration-500 ease-out  flex justify-between items-center border-t-2 border-violet-300 text-sm`}>
                        CTOP Work Healthcare
                        <span className="material-symbols-outlined">
                            {
                                workCare ?
                                'expand_less'
                                :
                                'expand_more'
                            }
                        </span>
                    </li>
                    {
                        workCare?
                        <ul className='bg-violet-200  rounded-sm p-5'>
                            <li onClick={()=> pathchoose("/workCareMedicle")} className=' my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-4' >
                                Workcare Medical
                            </li>
                            <li onClick={()=> window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLScj8thMXMRcgKJFEIT-nKkBUKKXH4lQkJckjrp_6UZ3GKkzmA/viewform?usp=sf_link'} className=' my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-4' >
                                Workcare Site Visit
                            </li>
                            <li onClick={()=> window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLScj8thMXMRcgKJFEIT-nKkBUKKXH4lQkJckjrp_6UZ3GKkzmA/viewform?usp=sf_link'} className=' my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-4' >
                                Care Service Booking
                            </li>
                            <li className=' my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-4' >
                                Employee Family Care
                            </li>
                            <li onClick={()=>{window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSdx57l0ZUZNctasszCmroAJAka8SsRGJCdik-LYKUpJsxaNVg/viewform?usp=sf_link'}} className=' my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-4' >
                                Organisation Subscription
                            </li>
                        </ul>
                        
                        :
                        ''
                    }
                    
                    <li onClick={()=> navigate('/csr')} className='px-5 cursor-pointer py-3 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 text-sm'>
                            CSR Initiative
                    </li>
                    <li onClick={()=> navigate('/program/team')} className='px-5 cursor-pointer py-3 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 text-sm'>
                            Team
                    </li>
                    <li onClick={()=> pathchoose('/narsing')} className='px-5 cursor-pointer py-3 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 text-sm'>
                        Nursing Health 
                    </li>
                    <li onClick={()=> pathchoose('/telehealth')} className='px-5 cursor-pointer py-3 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 text-sm'>
                        Online Telehelth
                    </li>
                    <li onClick={()=> pathchoose('/University&School')} className='px-5 cursor-pointer py-3 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 text-sm'>
                        School & Univarsity Healthcare 
                    </li>
                    
                    <li onClick={()=> pathchoose('/droneservices')} className='px-5 cursor-pointer py-3 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 text-sm'>
                        Drone Services
                    </li>
                    <li onClick={()=>pathchoose('/mentalHelth')} className='px-5 cursor-pointer py-3 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 text-sm'>
                        Mental Health
                    </li>
                    
                    {/* <li onClick={()=>pathchoose('/mentalHelth')} className='px-5 cursor-pointer py-3 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 text-sm'>
                        Women Health
                    </li> */}
                    <li onClick={()=> pathchoose('/projects')} className='px-5 cursor-pointer py-3 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 text-sm'>
                        CTOP Projects
                    </li>
                    <li onClick={()=> pathchoose('/consumables')} className='px-5 cursor-pointer py-3 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 text-sm'>
                        Consumables
                    </li>
                    <li onClick={()=> pathchoose('/annualSubsription')} className='px-5 cursor-pointer py-3 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 text-sm'>
                        Subscriptions
                    </li>
                    <li onClick={()=> pathchoose('/appointment')}  className='px-5 cursor-pointer py-3 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 text-sm'>
                    Appointments
                    </li>
                    <li onClick={()=> window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSeApe1LOulwTHFuedmTIXNnEDoeirlySEnd1rH84naMr1XJYQ/viewform?pli=1'} className='px-5 cursor-pointer py-3 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 text-sm'>
                        Assesment
                        </li>
                    <li onClick={()=> pathchoose('/prescription')}  className='px-5 cursor-pointer py-3 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 text-sm'>
                    Prescriptions
                    </li>
                    <li onClick={()=> pathchoose('/mentalHelth')}  className='px-5 cursor-pointer py-3 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 text-sm'>
                    Mental Health
                    </li>
                    
                    <li onClick={()=> pathchoose('/gallery')}  className='px-5 cursor-pointer py-3 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 text-sm'>
                        Gallery
                        </li>
                        
                        <li onClick={()=> pathchoose('/gallery')}  className='px-5 cursor-pointer py-3 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 text-sm'>
                        Events
                        </li>
                        
                        
                        <li onClick={()=> pathchoose('/faqs')}  className='px-5 cursor-pointer py-3 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 text-sm'>
                        FAQ
                        </li>
                        
                </ul>
                <button onClick={()=> navigate('/login')} className={`mx-5 md:block ${isMenuBar ? "hidden" : "block"}  text-white p-1 rounded-md bg-orange-400`} >Log in</button>
            </div>
            
        </div>
    </div>
    )
}