import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AdminAuthoritiesContext } from '../../context/ContextForAdminPages';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

function Calendar() {
    
    // ! error to show the form status
    const [error,setError] = useState('');
    const [errorShow,setErrorShow] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const {isAdmin,setIsAdmin} = useContext(AdminAuthoritiesContext);
    const [month, setMonth] = useState(1);
    const [year, setYear] = useState();
    const [noOfDays, setNoOfDays] = useState([]);
    const [blankdays, setBlankDays] = useState([]);
    const [events, setEvents] = useState([
    ]);
    const [eventDate, setEventDate] = useState('');
    const [availabiltyTiming,setAvailabiltyTiming] = useState('Morning');
    const [openEventModal, setOpenEventModal] = useState(false);


    const getNoOfDays = useMemo(() => (status) => {
        var daysInMonth = '';
        var dayOfWeek ='';
        console.log(month);
        if(status){
            daysInMonth = new Date(year, month + 1 , 0).getDate();
            dayOfWeek = new Date(year, month + 1 , 0).getDay();
        } else{
            daysInMonth = new Date(year, month , 0).getDate();
            dayOfWeek = new Date(year, month, 0).getDay();
        }
        const blankdaysArray = Array.from({ length: dayOfWeek }, (_, i) => i + 1);
        const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);
        setBlankDays(blankdaysArray);
        setNoOfDays(daysArray);
    }, [month, year]);


    useEffect(()=>{
      
      async function getCalender(){
        // console.log(isAdmin?.user?.email);
        await fetch(`http://localhost:500/drProfile/getCalender`,{
            headers: {'Content-Type': 'application/json'},
            method: 'POST',
            credentials:'include',
            body: JSON.stringify({
                email:isAdmin?.user?.email
            })
        })
        .then(response => response.json())
        .then(data=>{
            if(data.status){
                console.log(data.data);
                setEvents(data.data)
                setError(data.message);
                setFormSubmitted(true);
            } else{
                setError(data.message);
                setErrorShow(true)
            }
        })
        .catch(err => {
            setError(err.message);
            setErrorShow(true)
        });
    }
    if(isAdmin?.user?.role !== undefined){
    getCalender();
    initDate();
    }
    },[isAdmin])

  const initDate = () => {
    const today = new Date();
    setMonth(today.getMonth());
    setYear(today.getFullYear());
  };

  const isToday = (date) => {
    const today = new Date();
    const d = new Date(year, month, date);
    return today.toDateString() === d.toDateString();
  };

  const showEventModal = (date) => {
    setOpenEventModal(true);
    setEventDate(new Date(year, month, date).toDateString());
    console.log(new Date(year, month, date).toDateString());
  };

  const addEvent = async() => {
        if (!availabiltyTiming) {
            setError("choose available timing");
            setErrorShow(true);
            return;
        }
        
        console.log(eventDate);
        
        const newEvent = {
            email:isAdmin?.user?.email,
            event_date: eventDate,
            // todo add this evebnt theme to better user interaction with the webpage   event_theme:event_theme,
            availabiltyTiming: availabiltyTiming
        };

        await fetch(`http://localhost:500/drProfile/updateCalender`,{
            headers: {'Content-Type': 'application/json'},
            method: 'POST',
            credentials:'include',
            body: JSON.stringify(newEvent)
        })
        .then(response => response.json())
        .then(data=>{
            if(data.status){
                setError(data.message);
                setFormSubmitted(true);
            } else{
                setError(data.message);
                setErrorShow(true)
            }
        })
        .catch(err => {
            setError(err.message);
            setErrorShow(true)
        });

        setEvents([...events, newEvent]);
        setAvailabiltyTiming('');
        setEventDate('');
        setOpenEventModal(false);
    };

//   console.log(month);
// const getNoOfDays = (status) => {
    
//     var daysInMonth = '';
//     var dayOfWeek ='';
//     console.log(month);
//     if(status){
//         daysInMonth = new Date(year, month  , 0).getDate();
//         dayOfWeek = new Date(year, month , 0).getDay();
//     } else{
//         daysInMonth = new Date(year, month , 0).getDate();
//         dayOfWeek = new Date(year, month, 0).getDay();
//     }
//     const blankdaysArray = Array.from({ length: dayOfWeek }, (_, i) => i + 1);
//     const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);
//     setBlankDays(blankdaysArray);
//     setNoOfDays(daysArray);
// };


  return (
    <div className="antialiased sans-serif bg-gray-100 h-fit">
      <div className="container mx-auto py-2 md:py-24">
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <div className="flex items-center justify-between py-2 px-6">
            <div>
              <span className="text-lg font-bold text-gray-800">{MONTH_NAMES[month]}</span>
              <span className="ml-1 text-lg text-gray-600 font-normal">{year}</span>
            </div>
            <div className="border rounded-lg px-1" style={{ paddingTop: '2px' }}>
              <button
                type="button"
                className={`leading-none rounded-lg transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 items-center ${month === 0 && 'cursor-not-allowed opacity-25'}`}
                disabled={month === 0}
                onClick={() => { 
                  let newMonth = ((month > 0 ? month : - month) - 1 + 12) % 12;
                  setMonth(newMonth); 
                  getNoOfDays(false); }
                }
              >
                <svg className="h-6 w-6 text-gray-500 inline-flex leading-none" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                </svg>
              </button>
              <div className="border-r inline-flex h-6"></div>
              <button
                type="button"
                className={`leading-none rounded-lg transition ease-in-out duration-100 inline-flex items-center cursor-pointer hover:bg-gray-200 p-1 ${month === 11 && 'cursor-not-allowed opacity-25'}`}
                disabled={month === 11}
                onClick={() => { 
                  let newMonth = ((month > 0 ? month : - month) + 1 + 12) % 12;
                  if(newMonth === 0){
                      newMonth = 2;
                  }
                  setMonth(newMonth); 
                  getNoOfDays(false); }
                }
              >
                <svg className="h-6 w-6 text-gray-500 inline-flex leading-none" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>
          </div>

          <div className="-mx-1 -mb-1">
            <div className="flex flex-wrap" >
              {DAYS.map((day, index) => (
                <div key={index} style={{ width: '14.26%' }} className="px-2 py-2">
                  <div className="text-gray-600 text-sm uppercase tracking-wide font-bold text-center">{day}</div>
                </div>
              ))}
            </div>

            <div className="flex flex-wrap border-t border-l">
              {blankdays.map((_, index) => (
                <div key={index} style={{ width: '14.28%', height: '120px' }} className="text-center border-r border-b px-4 pt-2"></div>
              ))}
              {noOfDays.map((date, dateIndex) => (
                <div key={dateIndex} style={{ width: '14.28%', height: '120px' }} className="px-4 pt-2 border-r border-b relative">
                  <div
                    onClick={() => showEventModal(date)}
                    className={`inline-flex w-6 h-6 items-center justify-center cursor-pointer text-center leading-none rounded-full transition ease-in-out duration-100 ${isToday(date) ? 'bg-blue-500 text-white' : 'text-gray-700 hover:bg-blue-200'}`}
                  >
                    {date}
                  </div>
                  <div style={{ height: '80px' }} className="overflow-y-auto mt-1">
                    {events.filter(event => new Date(event.event_date).toDateString() === new Date(year, month, date).toDateString()).map((event, index) => (
                      <div key={index} className={`px-2 py-1 rounded-lg mt-1 overflow-hidden border border-${event.event_theme}-200 text-${event.event_theme}-800 bg-${event.event_theme}-100`}>
                        <p className="text-sm truncate leading-tight">{event.availabiltyTiming}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {openEventModal && (
        <div className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full bg-opacity-80 bg-black flex justify-center items-center">
          <div className="max-w-xl mx-auto bg-white rounded-lg overflow-hidden shadow-lg">
            <div className="p-8">
              <h2 className="font-bold text-lg md:text-2xl text-center mb-6 text-gray-800 border-b pb-2">Add Availability Details</h2>
              <div className="mb-4">
                <label className="text-gray-800 block mb-1 font-bold text-sm tracking-wide">Event date</label>
                <input
                  type="text"
                  value={new Date(eventDate).toDateString()}
                  readOnly
                  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded-lg w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                />
              </div>
              
              <div className="inline-block w-64 mb-4">
                <label className="text-gray-800 block mb-1 font-bold text-sm tracking-wide">Select a Timing</label>
                <select
                  value={availabiltyTiming}
                  onChange={(e) => setAvailabiltyTiming(e.target.value)}
                  className="block appearance-none w-fit bg-gray-200 border-2 border-gray-200 hover:border-gray-500 px-4 py-2 pr-8 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-blue-500 text-gray-700"
                >
                    <option className=' text-xs' value="Morning">Morning</option>
                    <option className=' text-xs' value="Afternoon">Afternoon</option>
                    <option className=' text-xs' value="Evening">Evening</option>
                </select>
              </div>
              <div className="mt-8 text-right">
                <button
                  className="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded-lg shadow-sm mr-2"
                  onClick={() => setOpenEventModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-blue-950 hover:bg-blue-600 text-white font-semibold py-2 px-4 border border-gray-700 rounded-lg shadow-sm"
                  onClick={addEvent}
                >
                  Save Schedule
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* /Modal */}
      <Snackbar open={formSubmitted} autoHideDuration={2000} onClose={()=> setFormSubmitted(false)}>
                    <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={()=>  setFormSubmitted(false)}
                    severity="error"
                    style={{backgroundColor:'green',color:'black'}}
                    >
                        {error}
                    </MuiAlert>
                </Snackbar>
                <Snackbar open={errorShow} autoHideDuration={2000} onClose={()=> setErrorShow(false)}>
                    <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={()=> setErrorShow(false)}
                    severity="error"
                    style={{backgroundColor:'#ff9900',color:'black'}}
                    >
                        {error}
                    </MuiAlert>
            </Snackbar>
    </div>
  );
}

export default Calendar;
