export const checkAuth = () => {
    return fetch('http://localhost:500/auth/protected', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Failed to fetch');
        }

        return response.json();
    })
    .catch(error => {
        console.log(error);
    });
}
