import React, { createContext, useState, useContext } from 'react';

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [isMenuBar, setIsMenuBar] = useState(false);
  return (
    <SidebarContext.Provider value={{ isMenuBar, setIsMenuBar }}>
      {children}
    </SidebarContext.Provider>
  );
};


// Custom hook to consume the context
export const useSidebar = () => {
    const context = useContext(SidebarContext);
    if (!context) {
      throw new Error('useSidebar must be used within a SidebarProvider');
    }
    return context;
};