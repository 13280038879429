import React, { createContext, useEffect, useState } from "react";
import { checkAuth } from "../Component/Authenticate/CheckAuth";

export const AdminAuthoritiesContext = createContext();

export const AdminAuthoritiesProvider = (props) => {
  const [isAdmin, setIsAdmin] = useState(null);
  // useEffect(()=>{
  //   async function authStateSave(){
  //     const data = await checkAuth();
  //     await setIsAdmin(data);
  //   }
  //   authStateSave();
  // },[])

  return (
    <AdminAuthoritiesContext.Provider value={{ isAdmin, setIsAdmin }}>
      {props.children}
    </AdminAuthoritiesContext.Provider>
  );
};
