import React from 'react';


export default function SliderPages(props) {
    return(

        <div className=' animate-pulse p-20 bg-white h-full w-full'>
        <div className='h-[30vh] m-auto md:w-[80%] md:h-[50vh] bg-gray-400'>
        </div>
        <div className='flex flex-col justify-center items-center'>
            <div className='heading p-5 '>
                <div class="space-y-2">
                    <div class="h-4 bg-gray-400 rounded w-80"></div>
                    <div class="h-4 bg-gray-400 rounded w-80 "></div>
                    
                    <div class="h-4 bg-gray-400 rounded w-80"></div>
                    <div class="h-4 bg-gray-400 rounded w-80 "></div>
                    
                    <div class="h-4 bg-gray-400 rounded w-80"></div>
                    <div class="h-4 bg-gray-400 rounded w-80 "></div>
                </div>
            </div>
            <div className='grid grid-cols-2 md:grid-cols-3'>
                <div className='flex flex-col justify-between items-center p-5 shadow-lg shadow-slate-500 bg-white rounded-md md:m-4 m-2 hover:scale-105 transition-all duration-300 cursor-pointer'>
                    <div className='w-20 h-20 bg-gray-400'>
                        
                    </div>
                    <div>
                        <div class="h-4 bg-gray-400 rounded w-36 m-1"></div>
                        <div class="h-4 bg-gray-400 rounded w-36 m-1"></div>
                        <div class="h-4 bg-gray-400 rounded w-36 m-1"></div>
                        <div class="h-4 bg-gray-400 rounded w-36 m-1"></div>
                    </div>
                </div>
                <div className='flex flex-col justify-between items-center p-5 shadow-lg shadow-slate-500 bg-white rounded-md md:m-4 m-2 hover:scale-105 transition-all duration-300 cursor-pointer'>
                    <div className='w-20 h-20 bg-gray-400'>
                        
                    </div>
                    <div>
                        <div class="h-4 bg-gray-400 rounded w-36 m-1"></div>
                        <div class="h-4 bg-gray-400 rounded w-36 m-1"></div>
                        <div class="h-4 bg-gray-400 rounded w-36 m-1"></div>
                        <div class="h-4 bg-gray-400 rounded w-36 m-1"></div>
                    </div>
                </div>
                <div className='flex flex-col justify-between items-center p-5 shadow-lg shadow-slate-500 bg-white rounded-md md:m-4 m-2 hover:scale-105 transition-all duration-300 cursor-pointer'>
                    <div className='w-20 h-20 bg-gray-400'>
                        
                    </div>
                    <div>
                        <div class="h-4 bg-gray-400 rounded w-36 m-1"></div>
                        <div class="h-4 bg-gray-400 rounded w-36 m-1"></div>
                        <div class="h-4 bg-gray-400 rounded w-36 m-1"></div>
                        <div class="h-4 bg-gray-400 rounded w-36 m-1"></div>
                    </div>
                </div>
            </div>
        </div>
        <div className=' w-full flex justify-center items-center flex-col'>
            <div className=' md:flex-row flex-col '>
                <div className='flex flex-col md:flex-row'>
                    <div className='m-5 w-100 md:w-1/2  p-5 flex-1'>
                        <div class="h-4 bg-gray-400 rounded w-96 m-2"></div>
                        <div class="h-4 bg-gray-400 rounded w-96 m-2"></div>
                    </div>
                    <div className='md:w-1/2 h-1/2 bg-gray-400'>
                        
                    </div>
                </div>
            </div>
        </div>
        <div className='flex justify-center items-center flex-col md:text-left text-center'>
            <div className='grid grid-cols-2 md:grid-cols-3'>
                <div className=' text-left  shadow-lg m-3 p-5 w-36 h-36 bg-gray-400'>
                </div>
                <div className='bg-gray-400 text-left p-5 w-36 h-36 shadow-lg m-3'>
                </div>
                <div className='bg-gray-400 text-left p-5 w-36 h-36 shadow-lg m-3'>
                </div>
            </div>
            <div className=' w-[90%] bg-slate-400 h-64'>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3">
                <div className='bg-gray-400 text-left p-5 w-36 h-36 shadow-lg m-3'>
                </div>
                <div className='bg-gray-400 text-left p-5 w-36 h-36 shadow-lg m-3'>
                </div>
                <div className='bg-gray-400 text-left p-5 w-36 h-36 shadow-lg m-3'>
                </div>
            </div>
        </div>
        <div className=''>
            <div className='grid grid-cols-2 md:grid-cols-3 '>
            <div className='bg-gray-400 text-left p-5 shadow-lg m-3'>
                </div>
                <div className='bg-gray-400 text-left p-5 shadow-lg m-3'>
                </div>
                <div className='bg-gray-400 text-left p-5 shadow-lg m-3'>
                </div>
            </div>
        </div>
        </div>
    )
}