export async function changeStatusIfRequest(currentRequestId,newStatus){
    try {
        const response = await fetch(`http://localhost:500/ctopHub/UpdateStatus`,
                                {
                                    headers:{'Content-Type':'application/json'},
                                    method: 'POST',
                                    credentials:'include',
                                    body:JSON.stringify({
                                        currentRequestId:currentRequestId,
                                        status:newStatus
                                    })
                                });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return new Error(error);
    }
}