import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainBars from "./Component/mainPages/MainBars";
import NotFOund from "./Component/mainComponents/notFound/Notfound";
import "./App.css";
import SliderPages from "./PlaceHolder/SliderPages";
import { SidebarProvider } from "./Component/mainPages/sidebarProviderContext";
import Authenticated from "./Component/Authenticate/atuhterize";
import { useState } from "react";
import { AdminAuthoritiesProvider } from "./context/ContextForAdminPages";
import SetUserContext from "./Controllers/Autherize";

const LazyServiceRequestPage = React.lazy(() =>
  import("./Component/sidebarPages/ServiceRequestsByUser/MainServicePage")
);
const LazyPrescription = React.lazy(() =>
  import("./Component/mainComponents/prescriptions/prescription")
);
const LazyAppointment = React.lazy(() =>
  import("./Component/mainComponents/Appointment/appointment")
);
const LazyOnlineDr = React.lazy(() =>
  import("./Component/mainComponents/Appointment/OnlineDr/maindr")
);
const LazyWeightLoss = React.lazy(() =>
  import("./Component/mainComponents/WeightLoss/mainWeight")
);
const LazyMentalHelth = React.lazy(() =>
  import("./Component/mainComponents/mentalHelth/mentalHMain")
);
const LazyCertification = React.lazy(() =>
  import("./Component/mainComponents/certification/certificate")
);
const LazyAnnualSubscription = React.lazy(() =>
  import("./Component/mainComponents/subscription/annulaSubscription")
);
const LazyCtopProgramHome = React.lazy(() =>
  import("./Component/sidebarPages/CtopProgram/Home")
);
const LazyHealthBeing = React.lazy(() =>
  import("./Component/sidebarPages/CTOPPanchayatCare/health&wellbeing")
);
const LazyGroupHealth = React.lazy(() =>
  import("./Component/sidebarPages/CTOPPanchayatCare/Grouphealth")
);
const LazyMembershipPanchayat = React.lazy(() =>
  import("./Component/sidebarPages/CTOPPanchayatCare/membershipPanchayat")
);
const LazyNursing = React.lazy(() =>
  import("./Component/sidebarPages/Nursing/nursing")
);
const LazyNursingCareForm = React.lazy(() =>
  import("./Component/FormsForBackEnd/nursingForm")
);
const LazyTelehealth = React.lazy(() =>
  import("./Component/sidebarPages/Telehalth/telehealth")
);
const LazyTelehealthBooking = React.lazy(() =>
  import("./Component/FormsForBackEnd/telehealthBooking")
);
const LazyAboutus = React.lazy(() =>
  import("./Component/sidebarPages/aboutus/AboutUs")
);
const LazyTeam = React.lazy(() =>
  import("./Component/sidebarPages/CtopProgram/Team")
);
const LazyHomeHealthCare = React.lazy(() =>
  import("./Component/sidebarPages/homehalthCare/homehalthcare")
);
const LazyWorkCare = React.lazy(() =>
  import("./Component/sidebarPages/workCare/workCare")
);
const LazyConsumables = React.lazy(() =>
  import("./Component/sidebarPages/consumables/consumables")
);
const LazyHomeCareMembership = React.lazy(() =>
  import("./Component/sidebarPages/homehalthCare/MemberShip")
);
const LazyPanchayatSubscription = React.lazy(() =>
  import("./Component/sidebarPages/CTOPPanchayatCare/Subscription")
);
const LazyCareServices = React.lazy(() =>
  import("./Component/sidebarPages/CtopProgram/careservices")
);
const LazyPreregistrationForm = React.lazy(() =>
  import(
    "./Component/sidebarPages/Membership Registration/preregforms/PreregistrationForm"
  )
);
const LazyGallery = React.lazy(() =>
  import("./Component/sidebarPages/Gallery/gallery")
);
const LazyFAQ = React.lazy(() => import("./Component/sidebarPages/FAQ/faq"));
const LazyDrone = React.lazy(() =>
  import("./Component/sidebarPages/Drone/drone")
);
const LazySchool = React.lazy(() =>
  import("./Component/sidebarPages/School/School")
);
const LazyProjects = React.lazy(() =>
  import("./Component/sidebarPages/Project/project")
);
const LazyEnquiry = React.lazy(() =>
  import("./Component/sidebarPages/EnquiryForm/Enquiry")
);
const LazyMap = React.lazy(() =>
  import("./Component/sidebarPages/LocationMap/IndianMap")
);
const LazySubscription = React.lazy(() =>
  import("./Component/sidebarPages/EnquiryForm/GetSubscription")
);
const LazyBuisness = React.lazy(() =>
  import("./Component/mainComponents/Buisness/Buisness")
);
const LazyScreening = React.lazy(() =>
  import("./Component/mainComponents/Screening/screening")
);
const LazyCSR = React.lazy(() => import("./Component/sidebarPages/CSR/csr"));
const LazyLOGIN = React.lazy(() =>
  import("./Component/sidebarPages/LogIn/logIn")
);
const LazyWorkcareForm = React.lazy(() =>
  import("./Component/FormsForBackEnd/workcareForm")
);
const LazySchoolBookForm = React.lazy(() =>
  import("./Component/FormsForBackEnd/schoolUniBooking")
);
const LazyDashBoard = React.lazy(() => import("./DashBoard/Home"));
const LazyMainForm = React.lazy(() =>
  import("./Component/sidebarPages/Forms/MainForm")
);
function App() {
  const [userType, setUserType] = useState("user");
  return (
    <div className="App h-screen overflow-hidden">
      {/* <Authenticated user={setUserType}> */}
      <Router>
        <AdminAuthoritiesProvider>
          <SetUserContext>
            <SidebarProvider>
              <MainBars>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyCtopProgramHome />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/aboutus"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyAboutus />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/prescription"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyPrescription />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/mainForm"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyMainForm />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/onlineDr"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyOnlineDr />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/appointment"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyAppointment />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/weightloss"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyWeightLoss />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/mentalHelth"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyMentalHelth />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/certification"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyCertification />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/annualSubsription"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyAnnualSubscription />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/program/home"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyCtopProgramHome />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/program/team"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyTeam />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/program/care-services"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyCareServices />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/panchayat/healthBeing"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyHealthBeing />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/panchayat/groupInsurance"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyGroupHealth />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/panchayat/memberInsurance"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyMembershipPanchayat />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/panchayat/preregister"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyPreregistrationForm />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/panchayat/Subscription"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyPanchayatSubscription />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/narsing"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyNursing />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/nursing/careBookForm"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyNursingCareForm />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/telehealth"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyTelehealth />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="/telehealth/careBookForm"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyTelehealthBooking />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/HomeHealthCare"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyHomeHealthCare />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/HomeHealthCare/memberShip"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyHomeCareMembership />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/workCareMedicle"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyWorkCare />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="/workcare/careBookForm"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyWorkcareForm />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/consumables"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyConsumables />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/faqs"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyFAQ />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/gallery"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyGallery />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="/droneservices"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyDrone />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="/projects"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyProjects />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="/University&School"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazySchool />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="/University&School/careBookForm"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazySchoolBookForm />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/Enquiry"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyEnquiry />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/CurrentLocations"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyMap />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="/GetSubscription"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazySubscription />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="/pharmacy"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyPrescription />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="/buisness"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyBuisness />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/screening"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyScreening />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="/login"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyLOGIN />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="/csr"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyCSR />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="/dashBoard"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyDashBoard />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="/user_requests/service_request"
                    element={
                      <React.Suspense fallback={<SliderPages />}>
                        <LazyServiceRequestPage />
                      </React.Suspense>
                    }
                  />

                  <Route path="*" element={<NotFOund />} />
                </Routes>
              </MainBars>
            </SidebarProvider>
          </SetUserContext>
        </AdminAuthoritiesProvider>
      </Router>
      {/* </Authenticated> */}
    </div>
  );
}

export default App;
