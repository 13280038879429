import {React, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './navbar.css';
export default function Navbar(props){
  
  const navigate = useNavigate();
  
  return (
    <nav className="bg-gray-800 text-white sticky top-0 z-40 ">

      <div className='hidden md:flex w-screen z-40 bg-white justify-between items-center'>
          <div onClick={()=> navigate('/dashBoard')} className='bg-white p-2'>
            <img src="/images/logo/Logo1.jpg" alt="" className='rounded-md m-2 md:h-24 h-16'/>
          </div>
          <div>
            <h1 className='francois-one-regular text-4xl font-15 text-blue-950'>CTOP INDIA MISSION</h1>
          </div>
          <div className=' flex flex-wrap justify-center items-center'>
          <button  onClick={()=> navigate('/csr')}  className='hidden md:flex min-w-24 text-right justify-center bg-orange-300 shadow-lg shadow-slate-400 text-blue-950 font-extrabold p-2 rounded-md ml-1 text-xs'>CSR Initiative</button>
            <button  onClick={()=> navigate('/CurrentLocations')}  className='hidden md:flex min-w-24 text-right justify-center bg-orange-300 shadow-lg shadow-slate-400 text-blue-950 font-extrabold p-2 rounded-md ml-1 text-xs'>Project Loaction</button>
            <div className='relative group z-50'>
              <button onClick={()=> window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLScj8thMXMRcgKJFEIT-nKkBUKKXH4lQkJckjrp_6UZ3GKkzmA/viewform?usp=sf_link'} className='hidden md:flex min-w-24 text-right justify-center bg-orange-300 shadow-lg shadow-slate-400 text-blue-950 font-extrabold p-2 rounded-md ml-1 text-xs'>Career</button>
              <ul className="absolute z-30 hidden group-hover:block right-0 text-xs font-bold bg-orange-50 text-gray-800 shadow-lg rounded-md">
                <li className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Internship</li>
                <li className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Placement</li>
                <li className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Training</li> 
                <li className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Job </li>
                <li className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Contract</li>
                <li className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Providers</li>
              </ul>
            </div>

            
            <div className=' relative group z-50'>
            <button  onClick={()=> navigate('/buisness')}   className='hidden md:flex min-w-24 text-right justify-center bg-orange-300 shadow-lg shadow-slate-400 text-blue-950 font-extrabold p-2 rounded-md ml-1 text-xs'>Business</button>
              <ul className="absolute z-30 hidden group-hover:block right-0 text-xs font-bold bg-orange-50 text-gray-800 shadow-lg rounded-md">
                <li className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Investment</li>
                <li className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Franchise</li>
                <li className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Distributer</li>
                <li className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Contract</li>
                <li className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Vendor Supply</li>
                <li className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Providers</li>
              </ul>
            </div>
            <button  onClick={()=> window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLScj8thMXMRcgKJFEIT-nKkBUKKXH4lQkJckjrp_6UZ3GKkzmA/viewform?usp=sf_link'}  className='hidden md:flex min-w-24 text-right justify-center bg-orange-300 shadow-lg shadow-slate-400 text-blue-950 font-extrabold p-2 rounded-md ml-1 text-xs'>Contact Us</button>
            <button  onClick={()=> {
              // navigate('/panchayat/preregister')
              navigate('/user_requests/service_request')
              // window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSdx57l0ZUZNctasszCmroAJAka8SsRGJCdik-LYKUpJsxaNVg/viewform?usp=sf_link'
              }}    className='hidden md:flex min-w-24 text-right justify-center bg-orange-300 shadow-lg shadow-slate-400 text-blue-950 font-extrabold p-2 rounded-md ml-1 text-xs'>Service Request</button>
              <div className='text-black flex mx-5  justify-center items-center'>
                <a href="
                tel:9019666699">
                  <span className="material-symbols-outlined text-black">
                    call
                  </span>
                </a>
              </div>
          </div>
      </div>

      <div className='md:hidden flex w-screen  p-2 md:px-16 bg-white justify-between items-center'>
          <div className='bg-white'>
            <img src="/images/logo/CRT_Logo.png" alt="" className='rounded-md md:h-24 h-16'/>
          </div>
          
          <div>
            <h1 className='francois-one-regular text-xl font-15 text-blue-950'>CTOP INDIA MISSION</h1>
          </div>
          <div className=' flex justify-center items-center '>
            <div className='text-black flex mx-5  justify-center items-center'>
              <a href="tel:+919019666699" className=' text-4xl'>
                <span className="material-symbols-outlined text-violet-700">
                  call
                </span>
              </a>
            </div>
            {/* <button  onClick={()=> navigate('/panchayat/preregister')}  className=' md:flex bg-orange-500 font-extrabold p-1 rounded-md m-1 text-xs'>Member Booking</button>
            <button onClick={()=> window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLScj8thMXMRcgKJFEIT-nKkBUKKXH4lQkJckjrp_6UZ3GKkzmA/viewform?usp=sf_link'}  className=' md:flex bg-orange-500 font-extrabold p-1 rounded-md m-1 text-xs'>Contact Us</button> */}
          </div>
      </div>
      
    </nav>
  )
}