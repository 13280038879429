import React, { useEffect, useState } from 'react';
import Navbar from './navbar';
import Sidenavbar from './sidenavbar';
import Navigation from './navigation';
import { useSidebar } from './sidebarProviderContext';


export default function MainBars({children}) {
    const [isSidebar,setIsSidebar] = useState(true);

    return(
        <div className='h-screen w-screen overflow-x-hidden relative'>
            <div className='h-fit'>
                <Navbar setIsSidebar= {setIsSidebar} IsSidebar={isSidebar}/>
            </div>
                <Navigation isSidebar={isSidebar} setIsSidebar={setIsSidebar}/>
            <div className='flex h-full w-screen relative'>
                {
                    isSidebar?
                    <Sidenavbar/>
                    :
                    ''
                }
                <div className={`${isSidebar ? 'md:w-[83%]  overflow-y-scroll' : 'w-[100%]'} `}>
                    {children}
                </div>
            </div>
        </div>
    )
}